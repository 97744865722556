<template>
	<g>
		<rect
			stroke="#FF0000FF"
			stroke-width="1"
			fill="none"
			x="-450"
			y="-450"
			width="900"
			height="900"
		/>
		<transition name="quickfade">
			<g>
				<rect
					stroke="#00000020"
					stroke-width="10"
					fill="#FFFFFF88"
					x="-300"
					y="-420"
					width="600"
					height="110"
					rx="15"
				/>
				<text
					opacity="1.0"
					x="25"
					y="-330"
					class="txt"
					text-anchor="end"
					dominant-baseline="auto"
					font-family="sans-serif"
					font-size="96"
					fill="#000000CC"
					>{{ question }}</text
				>
				<text
					opacity="1.0"
					x="50"
					y="-330"
					class="txt"
					text-anchor="start"
					dominant-baseline="auto"
					font-family="sans-serif"
					font-size="96"
					fill="#000000CC"
					>{{ answer }}</text
				>
			</g>
		</transition>
		<transition name="quickfade">
			<g v-if="condition == 'A'">
				<g
					v-for="(v, idx) in alt"
					:key="idx"
					v-on:click="click_digit(idx)"
				>
					<rect
						stroke="#00000020"
						stroke-width="5"
						:fill="btn_colour(idx)"
						:x="v.x * 110 - 47"
						:y="v.y * 110 - 47 + 50"
						width="95"
						height="95"
						rx="15"
					/>
					<text
						:x="v.x * 110"
						:y="v.y * 110 + 56"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="50"
						fill="#FFFFFF"
						>{{ idx }}</text
					>
				</g>
				<g v-on:click="click_ok()">
					<rect
						stroke="#00000020"
						stroke-width="5"
						:fill="btn_ok_colour()"
						x="190"
						y="-80"
						width="120"
						height="120"
						rx="15"
					/>
					<text
						x="250"
						y="-17"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="40"
						fill="#FFFFFF"
						>OK</text
					>
				</g>
				<g v-on:click="click_erase()">
					<rect
						stroke="#00000020"
						stroke-width="5"
						:fill="btn_erase_colour()"
						x="190"
						y="60"
						width="120"
						height="120"
						rx="15"
					/>
					<text
						x="247"
						y="123"
						class="txt"
						text-anchor="middle"
						dominant-baseline="middle"
						font-family="sans-serif"
						font-size="40"
						fill="#FFFFFF"
						>⌫</text
					>
				</g>
			</g>
		</transition>

		<transition name="quickfade">
			<g v-if="condition == 'B'">
				<g v-for="(vy, iy) in 12" :key="iy">
					<g v-for="(vx, ix) in 12" :key="ix">
						<rect
							stroke="#00000020"
							stroke-width="3"
							:fill="cell_col[ix * 12 + iy]"
							:x="ix * 64 - 360 - 20"
							:y="iy * 60 - 260 - 20"
							width="60"
							height="55"
							rx="5"
						/>
						<text
							:x="ix * 64 - 360 + 9"
							:y="iy * 60 - 250"
							class="txt"
							text-anchor="middle"
							dominant-baseline="middle"
							font-family="sans-serif"
							font-size="32"
							fill="#FFFFFF"
							>{{ cell_val[ix * 12 + iy] }}</text
						>
						<!-- grid_colour(vx, vy) -->
						<!-- {{ vx * vy }} -->
					</g>
				</g>
			</g>
		</transition>
	</g>
</template>

<script>
import { store } from "@/store.js";
import persist from "@/persist.js";
//import stat from "@/stat.js";

export default {
	name: "choke_practice_multiplication",
	components: {
		//timeline,
		//instructions,
	},
	//props: ['stim_param'],
	//mixins: [lang],
	computed: {
		text() {
			return 0;
			//return this.getLocalisedText();
		},
		question() {
			return "7·8 =";
			// return (
			// 	this.items[this.trial] || {
			// 		block: -1,
			// 		task: "",
			// 		n: -2,
			// 		quad: -1,
			// 		stim: "0",
			// 		shift: 0,
			// 		comp: 0,
			// 		neg: 0,
			// 		cresp: "",
			// 	}
			//);
			//return this.items[this.trial] || [-1, '', -2, -1, 99, 0, 0, 0, '', '', ''];
		},
	},
	watch: {
		item: function () {
			//this.item_start_time = Date.now();
		},
		/*item_visible: function() {
			console.log('ready? ' + this.item_visible);
		}*/
	},
	data: () => {
		return {
			start_time: 0,
			show_buttons: true,
			input_disabled: false,
			items: [],
			trial: 0,
			condition: "B",
			alt: [
				{ x: 0, y: 2 },
				{ x: -1, y: -1 },
				{ x: 0, y: -1 },
				{ x: 1, y: -1 },
				{ x: -1, y: 0 },
				{ x: 0, y: 0 },
				{ x: 1, y: 0 },
				{ x: -1, y: 1 },
				{ x: 0, y: 1 },
				{ x: 1, y: 1 },
			],
			balanced_list: [
				1, 3, 7, 9, 1, 3, 7, 9, 1, 3, 7, 9, 1, 3, 7, 9, 2, 4, 6, 8, 2,
				4, 6, 8, 2, 4, 6, 8, 2, 4, 6, 8,
			],
			btn_ok_down: false,
			btn_erase_down: false,
			btn_state: [
				false,
				false,
				false,
				false,
				false,
				false,
				false,
				false,
				false,
				false,
			],
			question_x: 8,
			question_y: 7,
			answer: "",
			cell_val: [],
			cell_col: [],
			cell_ord: [],
		};
	},
	methods: {
		click_digit(idx) {
			if (!this.btn_state[idx]) {
				if (this.answer.length < 3) {
					this.answer = this.answer + idx;
					this.$set(this.btn_state, idx, true);
					setTimeout(() => {
						this.$set(this.btn_state, idx, false);
					}, 100);
				}
			}
		},
		click_ok() {
			if (!this.btn_ok_down) {
				this.btn_ok_down = true;
				setTimeout(() => {
					this.btn_ok_down = false;
				}, 100);
			}
		},
		click_erase() {
			if (!this.btn_erase_down) {
				if (this.answer.length > 0) {
					this.answer = this.answer.substring(
						0,
						this.answer.length - 1
					);

					this.btn_erase_down = true;
					setTimeout(() => {
						this.btn_erase_down = false;
					}, 100);
				}
			}
		},
		grid_colour(vx, vy) {
			if (vx == 1 || vy == 1) {
				if (vx == this.question_x || vy == this.question_y) {
					return "#77CC77FF";
				} else {
					return "#77777722";
				}
			} else {
				if (vx == this.question_x || vy == this.question_y) {
					return "#778477FF";
				} else {
					return "#777777FF";
				}
			}
		},
		btn_ok_colour() {
			if (this.answer.length == 0) return "#66BB6622";
			return this.btn_ok_down ? "#99EE99FF" : "#66BB66FF";
		},
		btn_erase_colour() {
			if (this.answer.length == 0) return "#BB666622";
			return this.btn_erase_down ? "#EE9999FF" : "#BB6666FF";
		},
		btn_colour(idx) {
			if (this.answer.length == 3) return "#77777722";
			return this.btn_state[idx] ? "#ccccff99" : "#77777788";
		},
		end_test() {
			this.show_buttons = false;
			setTimeout(() => {
				store.commit("setTaskMode", "clickToExit");
				this.saveSessionResults();
			}, 10000);
		},
		saveSessionResults() {
			const total_time = Date.now() - this.start_time;

			persist.set_progress_data("choke_multiple_choice_test", 1);

			persist.log("results", {
				score: 123,
				start_time: this.start_time,
				total_time: Math.trunc(total_time),
			});
		},
		clicked_a() {
			this.log_response("a");
		},
		clicked_b() {
			this.log_response("b");
		},
		clicked_c() {
			this.log_response("c");
		},
		clicked_d() {
			this.log_response("d");
		},
		log_response(response) {
			if (response == "a") this.items.push("a");
			if (response == "ok") {
				this.btn_ok_down = true;
				setTimeout(() => {
					this.btn_ok_down = false;
				}, 100);
			}
			// setTimeout(() => {
			// 	++this.trial;
			// 	if (this.trial >= this.items.length) this.end_test();
			// }, 150);
		},
		setup() {
			store.commit("setTaskMode", "playing");
			//store.commit('setTaskMode', 'readyImmediateInstruct');
			//store.commit("setTaskMode", "ready_no_exit");
			store.commit("taskPhase", 0);
			this.$parent.show_buttons = false;
		},
		create_alt(x, y, x2, y2) {
			if (x2 < 2 || x2 > 12 || y2 < 2 || y2 > 12) return null;
			if (x2 * y2 < 1 || x2 * y2 > 144) return null;
			//if ((x * y) % 2 != (x2 * y2) % 2) return null;
			let diff = Math.abs(x2 * y2 - x * y);
			if (diff == 0) return null; // 3*4 == 4*3
			return {
				x: x2,
				y: y2,
				r: x2 * y2,
				diff: diff,
			};
		},
		smallest_prod(alts) {
			var val = 9999;
			for (var i = 0; i < alts.length; i++)
				val = Math.min(val, alts[i].r);
			return val;
		},
		largest_prod(alts) {
			var val = -9999;
			for (var i = 0; i < alts.length; i++)
				val = Math.max(val, alts[i].r);
			return val;
		},
		/*create_test_items() {
			var items = [];

			var x, y, n, prev_r;

			var alt, alts, it; //, n_ok;
			var set;

			var num_smallest, num_biggest;
			num_smallest = 0;
			num_biggest = 0;

			var num_smaller, num_bigger;
			var num_three_smaller = 0,
				num_three_bigger = 0;

			var num_three_smaller_a = 0,
				num_three_bigger_a = 0;

			var num_three_smaller_b = 0,
				num_three_bigger_b = 0;

			var num_a = 0,
				num_b = 0;

			var nn;
			var pos;

			var apos = [];
			var bpos = [];
			var ppos = [];

			for (nn = 0; nn < 20; nn++) {
				apos.push(0);
				bpos.push(2);
			}
			for (nn = 0; nn < 20; nn++) {
				apos.push(1);
				bpos.push(1);
			}
			for (nn = 0; nn < 20; nn++) {
				apos.push(2);
				bpos.push(0);
			}
			do stat.shuffle(apos);
			while (apos[59] != 2);

			do stat.shuffle(bpos);
			while (
				bpos[0] != 0 ||
				bpos[5] != 0 ||
				bpos[54] != 2 ||
				bpos[59] != 2
			);
			apos.unshift(0);

			nn = -1;
			var na = 0;
			var nb = 0;

			// 0: lowest number is the correct answer
			// 1: a middle number is the correct answer
			// 2: highest number is the correct answer
			let lo_mid_hi_correct = [
				0, 0, 0, 1, 1, 0, 2, 1, 1, 2, 1, 0, 0, 2, 0, 0, 0, 0, 1, 2, 1,
				0, 2, 0, 0, 0, 1, 2, 0, 2, 1, 1, 1, 2, 1, 2, 0, 2, 2, 0, 2, 1,
				2, 0, 1, 1, 1, 0, 2, 0, 1, 1, 2, 1, 2, 2, 2, 1, 2, 1, 0, 1, 1,
				2, 0, 1, 0, 1, 2, 0, 2, 2, 2, 2, 2, 1, 0, 1, 0, 0, 0, 2, 1, 0,
				0, 2, 1, 1, 1, 1, 0, 2, 2, 1, 1, 0, 0, 0, 1, 2, 0, 1, 2, 0, 0,
				1, 1, 2, 0, 2, 2, 0, 2, 1, 2, 0, 1, 2, 0, 2, 2,
			];

			for (x = 0; x < 12; x++) {
				for (y = 0; y < 12; y++) {
					if (x < 1 || y < 1) {
						this.cell_val.push(((x + 1) * (y + 1)).toString());
					} else {
						this.cell_val.push(nn.toString());

						if (x + y <= 3) {
							if ((x + y) % 2 == 0) console.log("lo A " + na);
							else console.log("lo B " + nb);
						} else if (x + y >= 21) {
							if ((x + y) % 2 == 0) console.log("hi A " + na);
							else console.log("hi B " + nb);
						}

						if ((x + y) % 2 == 0) {
							// A
							pos = apos[na++];
							ppos.push(pos);
						} else {
							//B
							pos = bpos[nb++];
							ppos.push(pos);
						}
						pos = lo_mid_hi_correct[nn + 1];
						nn += 1;
					}

					//this.cell_val.push(".");

					//this.cell_ord()

					if (x < 1 || y < 1) {
						this.cell_col.push("#88888822");
					} else {
						if (pos == 0) {
							this.cell_col.push(
								(x + y) % 2 == 0 ? "#ffee0088" : "#00eeff88"
							);
						} else if (pos == 1) {
							this.cell_col.push(
								(x + y) % 2 == 0 ? "#88770088" : "#00778888"
							);
						} else {
							this.cell_col.push(
								(x + y) % 2 == 0 ? "#22110088" : "#00112288"
							);
						}
					}
				}
			}

			for (x = 2; x <= 12; x++) {
				for (y = 2; y <= 12; y++) {
					set = (x + y) % 2;
					if (set == 0) num_a++;
					else num_b++;

					console.log(" ");
					console.log("----------");
					console.log(
						(set == 0 ? "A" : "B") +
							" " +
							x +
							"·" +
							y +
							" = " +
							x * y
					);
					alts = [];
					alt = this.create_alt(x, y, x, y - 2);
					if (alt != null) alts.push(alt);

					alt = this.create_alt(x, y, x, y + 2);
					if (alt != null) alts.push(alt);

					alt = this.create_alt(x, y, x - 2, y);
					if (alt != null) alts.push(alt);

					alt = this.create_alt(x, y, x + 2, y);
					if (alt != null) alts.push(alt);

					// ---
					alt = this.create_alt(x, y, x - 1, y - 1);
					if (alt != null) alts.push(alt);

					alt = this.create_alt(x, y, x - 1, y + 1);
					if (alt != null) alts.push(alt);

					alt = this.create_alt(x, y, x + 1, y - 1);
					if (alt != null) alts.push(alt);

					alt = this.create_alt(x, y, x + 1, y + 1);
					if (alt != null) alts.push(alt);

					// ---
					alt = this.create_alt(x, y, x - 2, y - 2);
					if (alt != null) alts.push(alt);

					alt = this.create_alt(x, y, x - 2, y + 2);
					if (alt != null) alts.push(alt);

					alt = this.create_alt(x, y, x + 2, y - 2);
					if (alt != null) alts.push(alt);

					alt = this.create_alt(x, y, x + 2, y + 2);
					if (alt != null) alts.push(alt);

					alts.sort((a, b) => a.diff - b.diff);

					var str;
					prev_r = -999;
					//n_ok = 0;
					var is_smallest = true;
					var is_biggest = true;
					num_smaller = 0;
					num_bigger = 0;
					//this.cell_val[(x - 1) * 12 + (y - 1)] =
					//	alts.length.toString();


					for (n = 0; n < alts.length; n++) {
						//if (n_ok >= 3) break;
						it = alts[n];
						if (it.r != prev_r) {
							prev_r = it.r;
							str =
								it.x +
								"·" +
								it.y +
								" = " +
								it.r +
								"    (" +
								it.diff +
								")";
							console.log(str);
							//n_ok++;

							is_smallest = is_smallest && x * y < it.x * it.y;
							is_biggest = is_biggest && x * y > it.x * it.y;

							if (x * y < it.x * it.y) num_smaller++;
							if (x * y > it.x * it.y) num_bigger++;
						}
					}
					if (is_smallest) num_smallest++;
					if (is_biggest) num_biggest++;
					if (num_smaller >= 3) {
						num_three_smaller++;
						console.log("<");
					}
					if (num_bigger >= 3) {
						num_three_bigger++;
						console.log(">");
					}

					if (set == 0) {
						if (num_smaller >= 3) {
							num_three_smaller_a++;
						}
						if (num_bigger >= 3) {
							num_three_bigger_a++;
						}
					} else if (set == 1) {
						if (num_smaller >= 3) {
							num_three_smaller_b++;
						}
						if (num_bigger >= 3) {
							num_three_bigger_b++;
						}
					}

					items.push({ x: x, y: y });
				}
			}
			console.log("num_total:    " + items.length);
			console.log("num_smallest: " + num_smallest);
			console.log("num_biggest:  " + num_biggest);
			console.log("num_three_smaller:   " + num_three_smaller);
			console.log("num_three_bigger:    " + num_three_bigger);
			console.log("num_three_smaller_A: " + num_three_smaller_a);
			console.log("num_three_bigger_A:  " + num_three_bigger_a);
			console.log("num_three_smaller_B: " + num_three_smaller_b);
			console.log("num_three_bigger_B:  " + num_three_bigger_b);
			console.log("num_A:  " + num_a);
			console.log("num_B:  " + num_b);
			console.log(JSON.stringify(ppos));
		},
		create_test_items2() {
			var items = [];

			var item;
			var alts, alt, set;
			var ord; // 0: smallest, 1: middle, 2: biggest
			var i = 0;

			let a_rand_ord = [
				2, 0, 1, 2, 1, 2, 1, 0, 2, 0, 1, 2, 0, 2, 1, 0, 2, 2, 0, 0, 1,
				1, 1, 0, 1, 2, 0, 2, 0, 0, 2, 1, 0, 2, 1, 0, 0, 2, 0, 1, 2, 1,
				2, 2, 1, 1, 2, 0, 0, 1, 0, 0, 1, 1, 2, 0, 1, 1, 2, 2,
			];
			let b_rand_ord = [
				2, 0, 1, 0, 1, 0, 2, 0, 1, 1, 0, 2, 1, 2, 2, 1, 0, 0, 2, 0, 0,
				1, 2, 1, 1, 2, 0, 2, 2, 0, 1, 2, 1, 2, 0, 2, 1, 0, 2, 2, 0, 1,
				1, 1, 0, 1, 1, 2, 1, 2, 2, 0, 2, 0, 2, 0, 1, 1, 0, 2,
			];


			var ai = 0;
			var bi = 0;

			var count_smaller;
			var count_bigger;
			var count;
			var r;

			for (var x = 2; x <= 12; x++) {
				for (var y = 2; y <= 12; y++) {
					if (x == 2 && y == 2) continue;

					set = (x + y) % 2; // 0: A, 1: B
					if (set == 0) ord = a_rand_ord[ai];
					else ord = b_rand_ord[bi];

					if ((x == 3 && y == 2) || (x == 2 && y == 3)) {
						ord = 0;
					} else if (x + y > 22) {
						ord = 2;
					}
					//else { ord = Math.trunc((i % 6) / 2); }

					i++;
					if (set == 0) ai++;
					else bi++;

					alts = [];
					alt = this.create_alt(x, y, x, y - 2);
					if (alt != null) alts.push(alt);

					alt = this.create_alt(x, y, x, y + 2);
					if (alt != null) alts.push(alt);

					alt = this.create_alt(x, y, x - 2, y);
					if (alt != null && alts.find((it) => it.r == alt.r) == null)
						alts.push(alt);

					alt = this.create_alt(x, y, x + 2, y);
					if (alt != null && alts.find((it) => it.r == alt.r) == null)
						alts.push(alt);

					// ---
					alt = this.create_alt(x, y, x - 1, y - 1);
					if (alt != null && alts.find((it) => it.r == alt.r) == null)
						alts.push(alt);

					alt = this.create_alt(x, y, x - 1, y + 1);
					if (alt != null && alts.find((it) => it.r == alt.r) == null)
						alts.push(alt);

					alt = this.create_alt(x, y, x + 1, y - 1);
					if (alt != null && alts.find((it) => it.r == alt.r) == null)
						alts.push(alt);

					alt = this.create_alt(x, y, x + 1, y + 1);
					if (alt != null && alts.find((it) => it.r == alt.r) == null)
						alts.push(alt);

					// ---
					alt = this.create_alt(x, y, x - 2, y - 2);
					if (alt != null && alts.find((it) => it.r == alt.r) == null)
						alts.push(alt);

					alt = this.create_alt(x, y, x - 2, y + 2);
					if (alt != null && alts.find((it) => it.r == alt.r) == null)
						alts.push(alt);

					alt = this.create_alt(x, y, x + 2, y - 2);
					if (alt != null && alts.find((it) => it.r == alt.r) == null)
						alts.push(alt);

					alt = this.create_alt(x, y, x + 2, y + 2);
					if (alt != null && alts.find((it) => it.r == alt.r) == null)
						alts.push(alt);

					alts.sort((a, b) => a.diff - b.diff);

					count_smaller = 0;
					count_bigger = 0;
					r = x * y;
					for (count = 0; count < alts.length; count++) {
						if (r > alts[count].r) count_smaller++;
						if (r < alts[count].r) count_bigger++;
					}

					if (count_smaller >= 3) {
						if (count_bigger >= 3) {
							ord = 1; // kan vara både minst och störst
						} else {
							ord = 2; // måste vara störst
						}
					} else {
						ord = 0; // måste vara minst
					}

					item = {
						x: x,
						y: y,
						q: x.toString() + "·" + y.toString(),
						r: x * y,
						set: set == 0 ? "A" : "B",
						alts: alts,
						ord: ord,
					};

					items.push(item);
				}
			}

			var giveup = 0;
			var ord_x = [];
			for (count = 0; count < items.length; count++) {
				ord_x.push(items[count].ord);
			}

			// 9 nya A smallest
			for (count = 0; count < 9; count++) {
				do {
					i = Math.trunc(Math.random() * items.length);
					if (items[i].set == "A" && items[i].ord == 1) break;
					giveup++;
				} while (giveup < 10000);
				items[i].ord = 0;
				if (giveup > 7000) break;
			}

			// 16 nya A biggest
			for (count = 0; count < 16; count++) {
				do {
					i = Math.trunc(Math.random() * items.length);
					if (items[i].set == "A" && items[i].ord == 1) break;
					giveup++;
				} while (giveup < 10000);
				items[i].ord = 2;
				if (giveup > 7000) break;
			}

			// 4 nya B smallest
			for (count = 0; count < 4; count++) {
				do {
					i = Math.trunc(Math.random() * items.length);
					if (items[i].set == "B" && items[i].ord == 1) break;
					giveup++;
				} while (giveup < 10000);
				items[i].ord = 0;
				if (giveup > 7000) break;
			}

			// 14 nya B biggest
			for (count = 0; count < 14; count++) {
				do {
					i = Math.trunc(Math.random() * items.length);
					if (items[i].set == "B" && items[i].ord == 1) break;
					giveup++;
				} while (giveup < 10000);
				items[i].ord = 2;
				if (giveup > 7000) break;
			}
			ord_x = [];
			for (count = 0; count < items.length; count++) {
				ord_x.push(items[count].ord);
			}

			// välj alternativ utifrån item.ord:
			var new_alts;
			for (i = 0; i < items.length; i++) {
				item = items[i];
				alts = item.alts;
				new_alts = [];

				if (item.ord == 0) {
					for (count = 0; count < alts.length; count++) {
						if (alts[count].r > item.r) {
							new_alts.push(alts[count].r);
							if (new_alts.length >= 3) break;
						}
					}
					item.alts = new_alts;
					item.balance = "";
				} else if (item.ord == 2) {
					for (count = 0; count < alts.length; count++) {
						if (alts[count].r < item.r) {
							new_alts.push(alts[count].r);
							if (new_alts.length >= 3) break;
						}
					}
					item.alts = new_alts;
					item.balance = "";
				} else {
					var balance = 0;
					for (count = 0; count < alts.length; count++) {
						if (balance >= -1 && alts[count].r < item.r) {
							balance -= 1;
							new_alts.push(alts[count].r);
							if (new_alts.length >= 3) break;
						} else if (balance <= 1 && alts[count].r > item.r) {
							balance += 1;
							new_alts.push(alts[count].r);
							if (new_alts.length >= 3) break;
						}
					}
					item.alts = new_alts;
					//item.balance = balance;
				}
			}

			var a_num_smallest = 0;
			var a_num_middle = 0;
			var a_num_biggest = 0;
			var b_num_smallest = 0;
			var b_num_middle = 0;
			var b_num_biggest = 0;
			for (i = 0; i < items.length; i++) {
				item = items[i];
				if (item.set == "A") {
					if (item.ord == 0) a_num_smallest++;
					else if (item.ord == 1) a_num_middle++;
					else a_num_biggest++;
				} else {
					if (item.ord == 0) b_num_smallest++;
					else if (item.ord == 1) b_num_middle++;
					else b_num_biggest++;
				}

				count_smaller = 0;
				count_bigger = 0;
				for (count = 0; count < item.alts.length; count++) {
					if (item.r > item.alts[count].r) count_bigger++;
					if (item.r < item.alts[count].r) count_smaller++;
				}

				console.log(
					item.q +
						" = " +
						item.r +
						"  -------  " +
						item.alts.length +
						"  ---  " +
						count_smaller +
						"  " +
						count_bigger
				);
			}
			console.log("---------------");
			console.log("A num_smallest: " + a_num_smallest);
			console.log("A num_middle:   " + a_num_middle);
			console.log("A num_biggest:  " + a_num_biggest);
			console.log("---------------");
			console.log("B num_smallest: " + b_num_smallest);
			console.log("B num_middle:   " + b_num_middle);
			console.log("B num_biggest:  " + b_num_biggest);
			console.log("---------------");

			for (x = 0; x < 12; x++) {
				for (y = 0; y < 12; y++) {
					this.cell_val.push(((x + 1) * (y + 1)).toString());
					if (x < 1 || y < 1) {
						this.cell_col.push("#88888822");
					} else {
						item = items.find(
							(it) => it.x === x + 1 && it.y === y + 1
						);
						if (item != null) {
							ord = item.ord;
						} else {
							ord = 0;
						}

						set = (x + y) % 2; // 0: A, 1: B
						if (ord == 0) {
							this.cell_col.push(
								set == 0 ? "#ffee0088" : "#00eeff88"
							);
						} else if (ord == 1) {
							this.cell_col.push(
								set == 0 ? "#88770088" : "#00778888"
							);
						} else {
							this.cell_col.push(
								set == 0 ? "#22110088" : "#00112288"
							);
						}
					}
				}
			}

			var str = "";

			var locs = [];
			var loc;
			for (set = 0; set <= 1; set++) {
				for (ord = 0; ord <= 2; ord++) {
					loc = [];
					for (count = 0; count < 20; count++) loc.push(count % 4);
					stat.shuffle(loc);

					console.log(
						(set == 0 ? "A" : "B") +
							ord.toString() +
							" " +
							(set * 3 + ord) +
							"   " +
							JSON.stringify(loc)
					);
					locs.push(loc);
				}
			}

			var loc_index;
			for (i = 0; i < items.length; i++) {
				item = items[i];
				alts = item.alts;
				set = item.set == "A" ? 0 : 1;
				ord = item.ord;
				loc_index = set * 3 + ord;
				//console.log("loc_index " + loc_index);
				//console.log(locs);
				item.loc = locs[loc_index].pop();
				//console.log("item.loc " + item.loc);
				stat.shuffle(alts);
				alts.splice(item.loc, 0, item.r);
				//alts.splice(2, 0, item.r);
			}

			var x_items = [];
			var x_item;

			console.log(items[0]);

			let csvContent = "n,x,y,r,set,ord,loc,a0,a1,a2,a3\n";

			for (i = 0; i < items.length; i++) {
				item = items[i];
				alts = item.alts;

				x_item = {
					x: item.x,
					y: item.y,
					r: item.r,
					set: item.set,
					ord: item.ord,
					loc: item.loc,
					alt: item.alts,
				};
				x_items.push(x_item);

				let row = `${i},${x_item.x},${x_item.y},${x_item.r},${x_item.set},${x_item.ord},${x_item.loc},${item.alts[0]},${item.alts[1]},${item.alts[2]},${item.alts[3]}\n`;
				csvContent += row;

				str = "";
				for (count = 0; count < alts.length; count++) {
					if (item.r < alts[count]) str += "<" + alts[count] + "\t";
					else if (item.r > alts[count])
						str += ">" + alts[count] + "\t";
					else str += "_" + alts[count] + "_\t";
				}
				console.log(
					i +
						"\t" +
						item.set +
						"\t" +
						item.q +
						"=" +
						item.r +
						"   \tord:" +
						item.ord +
						"   \tloc: " +
						item.loc +
						"\t\t" +
						str
					//"\t\t" +
					//item.balance
				);
			}
			console.log(JSON.stringify(x_items));
			console.log(csvContent);

			const numbers = Array.from({ length: 120 }, (_, i) => i);
			console.log(numbers);
			stat.shuffle(numbers);
			console.log(numbers);
			console.log(JSON.stringify(numbers));
		},
		set_test_items() {
			this.items = [
				{
					x: 2,
					y: 3,
					r: 6,
					set: "B",
					ord: 0,
					loc: 3,
					alt: [12, 10, 20, 6],
				},
				{
					x: 2,
					y: 4,
					r: 8,
					set: "A",
					ord: 0,
					loc: 1,
					alt: [15, 8, 9, 12],
				},
				{
					x: 2,
					y: 5,
					r: 10,
					set: "B",
					ord: 0,
					loc: 1,
					alt: [18, 10, 12, 14],
				},
				{
					x: 2,
					y: 6,
					r: 12,
					set: "A",
					ord: 0,
					loc: 1,
					alt: [21, 12, 16, 15],
				},
				{
					x: 2,
					y: 7,
					r: 14,
					set: "B",
					ord: 0,
					loc: 0,
					alt: [14, 18, 24, 20],
				},
				{
					x: 2,
					y: 8,
					r: 16,
					set: "A",
					ord: 0,
					loc: 1,
					alt: [21, 16, 24, 20],
				},
				{
					x: 2,
					y: 9,
					r: 18,
					set: "B",
					ord: 0,
					loc: 3,
					alt: [28, 22, 24, 18],
				},
				{
					x: 2,
					y: 10,
					r: 20,
					set: "A",
					ord: 0,
					loc: 2,
					alt: [24, 27, 20, 32],
				},
				{
					x: 2,
					y: 11,
					r: 22,
					set: "B",
					ord: 0,
					loc: 1,
					alt: [30, 22, 36, 44],
				},
				{
					x: 2,
					y: 12,
					r: 24,
					set: "A",
					ord: 0,
					loc: 2,
					alt: [33, 40, 24, 48],
				},
				{
					x: 3,
					y: 2,
					r: 6,
					set: "B",
					ord: 0,
					loc: 1,
					alt: [20, 6, 10, 12],
				},
				{
					x: 3,
					y: 3,
					r: 9,
					set: "A",
					ord: 0,
					loc: 1,
					alt: [15, 9, 16, 25],
				},
				{
					x: 3,
					y: 4,
					r: 12,
					set: "B",
					ord: 0,
					loc: 0,
					alt: [12, 20, 30, 18],
				},
				{
					x: 3,
					y: 5,
					r: 15,
					set: "A",
					ord: 1,
					loc: 0,
					alt: [15, 12, 9, 16],
				},
				{
					x: 3,
					y: 6,
					r: 18,
					set: "B",
					ord: 1,
					loc: 0,
					alt: [18, 20, 12, 14],
				},
				{
					x: 3,
					y: 7,
					r: 21,
					set: "A",
					ord: 2,
					loc: 3,
					alt: [16, 15, 12, 21],
				},
				{
					x: 3,
					y: 8,
					r: 24,
					set: "B",
					ord: 0,
					loc: 0,
					alt: [24, 30, 36, 28],
				},
				{
					x: 3,
					y: 9,
					r: 27,
					set: "A",
					ord: 2,
					loc: 0,
					alt: [27, 16, 20, 21],
				},
				{
					x: 3,
					y: 10,
					r: 30,
					set: "B",
					ord: 1,
					loc: 3,
					alt: [22, 36, 24, 30],
				},
				{
					x: 3,
					y: 11,
					r: 33,
					set: "A",
					ord: 1,
					loc: 3,
					alt: [24, 40, 27, 33],
				},
				{
					x: 3,
					y: 12,
					r: 36,
					set: "B",
					ord: 0,
					loc: 0,
					alt: [36, 60, 50, 44],
				},
				{
					x: 4,
					y: 2,
					r: 8,
					set: "A",
					ord: 0,
					loc: 0,
					alt: [8, 9, 12, 15],
				},
				{
					x: 4,
					y: 3,
					r: 12,
					set: "B",
					ord: 0,
					loc: 1,
					alt: [20, 12, 18, 30],
				},
				{
					x: 4,
					y: 4,
					r: 16,
					set: "A",
					ord: 2,
					loc: 0,
					alt: [16, 9, 12, 15],
				},
				{
					x: 4,
					y: 5,
					r: 20,
					set: "B",
					ord: 1,
					loc: 1,
					alt: [18, 20, 14, 28],
				},
				{
					x: 4,
					y: 6,
					r: 24,
					set: "A",
					ord: 1,
					loc: 3,
					alt: [25, 16, 21, 24],
				},
				{
					x: 4,
					y: 7,
					r: 28,
					set: "B",
					ord: 1,
					loc: 0,
					alt: [28, 24, 30, 20],
				},
				{
					x: 4,
					y: 8,
					r: 32,
					set: "A",
					ord: 1,
					loc: 3,
					alt: [27, 36, 35, 32],
				},
				{
					x: 4,
					y: 9,
					r: 36,
					set: "B",
					ord: 0,
					loc: 2,
					alt: [40, 42, 36, 44],
				},
				{
					x: 4,
					y: 10,
					r: 40,
					set: "A",
					ord: 0,
					loc: 0,
					alt: [40, 45, 55, 48],
				},
				{
					x: 4,
					y: 11,
					r: 44,
					set: "B",
					ord: 0,
					loc: 1,
					alt: [60, 44, 50, 54],
				},
				{
					x: 4,
					y: 12,
					r: 48,
					set: "A",
					ord: 0,
					loc: 3,
					alt: [60, 55, 72, 48],
				},
				{
					x: 5,
					y: 2,
					r: 10,
					set: "B",
					ord: 0,
					loc: 3,
					alt: [14, 12, 18, 10],
				},
				{
					x: 5,
					y: 3,
					r: 15,
					set: "A",
					ord: 1,
					loc: 2,
					alt: [12, 16, 15, 9],
				},
				{
					x: 5,
					y: 4,
					r: 20,
					set: "B",
					ord: 2,
					loc: 0,
					alt: [20, 12, 18, 14],
				},
				{
					x: 5,
					y: 5,
					r: 25,
					set: "A",
					ord: 1,
					loc: 0,
					alt: [25, 24, 21, 35],
				},
				{
					x: 5,
					y: 6,
					r: 30,
					set: "B",
					ord: 1,
					loc: 0,
					alt: [30, 40, 24, 28],
				},
				{
					x: 5,
					y: 7,
					r: 35,
					set: "A",
					ord: 1,
					loc: 0,
					alt: [35, 32, 27, 36],
				},
				{
					x: 5,
					y: 8,
					r: 40,
					set: "B",
					ord: 1,
					loc: 2,
					alt: [30, 36, 40, 42],
				},
				{
					x: 5,
					y: 9,
					r: 45,
					set: "A",
					ord: 1,
					loc: 0,
					alt: [45, 49, 40, 48],
				},
				{
					x: 5,
					y: 10,
					r: 50,
					set: "B",
					ord: 0,
					loc: 3,
					alt: [56, 60, 54, 50],
				},
				{
					x: 5,
					y: 11,
					r: 55,
					set: "A",
					ord: 1,
					loc: 2,
					alt: [60, 63, 55, 48],
				},
				{
					x: 5,
					y: 12,
					r: 60,
					set: "B",
					ord: 2,
					loc: 2,
					alt: [44, 36, 60, 50],
				},
				{
					x: 6,
					y: 2,
					r: 12,
					set: "A",
					ord: 0,
					loc: 2,
					alt: [16, 15, 12, 21],
				},
				{
					x: 6,
					y: 3,
					r: 18,
					set: "B",
					ord: 1,
					loc: 2,
					alt: [20, 12, 18, 14],
				},
				{
					x: 6,
					y: 4,
					r: 24,
					set: "A",
					ord: 2,
					loc: 1,
					alt: [15, 24, 21, 16],
				},
				{
					x: 6,
					y: 5,
					r: 30,
					set: "B",
					ord: 1,
					loc: 1,
					alt: [40, 30, 24, 28],
				},
				{
					x: 6,
					y: 6,
					r: 36,
					set: "A",
					ord: 1,
					loc: 2,
					alt: [48, 35, 36, 32],
				},
				{
					x: 6,
					y: 7,
					r: 42,
					set: "B",
					ord: 2,
					loc: 2,
					alt: [30, 40, 42, 36],
				},
				{
					x: 6,
					y: 8,
					r: 48,
					set: "A",
					ord: 2,
					loc: 2,
					alt: [45, 40, 48, 36],
				},
				{
					x: 6,
					y: 9,
					r: 54,
					set: "B",
					ord: 2,
					loc: 2,
					alt: [50, 44, 54, 42],
				},
				{
					x: 6,
					y: 10,
					r: 60,
					set: "A",
					ord: 1,
					loc: 3,
					alt: [64, 55, 63, 60],
				},
				{
					x: 6,
					y: 11,
					r: 66,
					set: "B",
					ord: 1,
					loc: 3,
					alt: [72, 70, 60, 66],
				},
				{
					x: 6,
					y: 12,
					r: 72,
					set: "A",
					ord: 0,
					loc: 1,
					alt: [96, 72, 80, 77],
				},
				{
					x: 7,
					y: 2,
					r: 14,
					set: "B",
					ord: 0,
					loc: 2,
					alt: [18, 24, 14, 20],
				},
				{
					x: 7,
					y: 3,
					r: 21,
					set: "A",
					ord: 2,
					loc: 0,
					alt: [21, 16, 15, 12],
				},
				{
					x: 7,
					y: 4,
					r: 28,
					set: "B",
					ord: 2,
					loc: 3,
					alt: [24, 18, 20, 28],
				},
				{
					x: 7,
					y: 5,
					r: 35,
					set: "A",
					ord: 2,
					loc: 3,
					alt: [27, 32, 25, 35],
				},
				{
					x: 7,
					y: 6,
					r: 42,
					set: "B",
					ord: 2,
					loc: 1,
					alt: [36, 42, 30, 40],
				},
				{
					x: 7,
					y: 7,
					r: 49,
					set: "A",
					ord: 0,
					loc: 3,
					alt: [64, 63, 81, 49],
				},
				{
					x: 7,
					y: 8,
					r: 56,
					set: "B",
					ord: 2,
					loc: 0,
					alt: [56, 50, 42, 54],
				},
				{
					x: 7,
					y: 9,
					r: 63,
					set: "A",
					ord: 2,
					loc: 3,
					alt: [60, 49, 55, 63],
				},
				{
					x: 7,
					y: 10,
					r: 70,
					set: "B",
					ord: 2,
					loc: 2,
					alt: [56, 66, 70, 60],
				},
				{
					x: 7,
					y: 11,
					r: 77,
					set: "A",
					ord: 0,
					loc: 3,
					alt: [80, 96, 81, 77],
				},
				{
					x: 7,
					y: 12,
					r: 84,
					set: "B",
					ord: 2,
					loc: 1,
					alt: [70, 84, 60, 66],
				},
				{
					x: 8,
					y: 2,
					r: 16,
					set: "A",
					ord: 0,
					loc: 3,
					alt: [20, 21, 24, 16],
				},
				{
					x: 8,
					y: 3,
					r: 24,
					set: "B",
					ord: 0,
					loc: 3,
					alt: [28, 36, 30, 24],
				},
				{
					x: 8,
					y: 4,
					r: 32,
					set: "A",
					ord: 1,
					loc: 1,
					alt: [36, 32, 35, 27],
				},
				{
					x: 8,
					y: 5,
					r: 40,
					set: "B",
					ord: 1,
					loc: 1,
					alt: [30, 40, 42, 36],
				},
				{
					x: 8,
					y: 6,
					r: 48,
					set: "A",
					ord: 1,
					loc: 0,
					alt: [48, 45, 40, 49],
				},
				{
					x: 8,
					y: 7,
					r: 56,
					set: "B",
					ord: 1,
					loc: 1,
					alt: [54, 56, 70, 50],
				},
				{
					x: 8,
					y: 8,
					r: 64,
					set: "A",
					ord: 2,
					loc: 3,
					alt: [60, 63, 49, 64],
				},
				{
					x: 8,
					y: 9,
					r: 72,
					set: "B",
					ord: 2,
					loc: 1,
					alt: [70, 72, 66, 56],
				},
				{
					x: 8,
					y: 10,
					r: 80,
					set: "A",
					ord: 1,
					loc: 2,
					alt: [81, 77, 80, 72],
				},
				{
					x: 8,
					y: 11,
					r: 88,
					set: "B",
					ord: 1,
					loc: 3,
					alt: [72, 90, 84, 88],
				},
				{
					x: 8,
					y: 12,
					r: 96,
					set: "A",
					ord: 1,
					loc: 3,
					alt: [99, 80, 100, 96],
				},
				{
					x: 9,
					y: 2,
					r: 18,
					set: "B",
					ord: 0,
					loc: 0,
					alt: [18, 28, 24, 22],
				},
				{
					x: 9,
					y: 3,
					r: 27,
					set: "A",
					ord: 2,
					loc: 1,
					alt: [21, 27, 20, 16],
				},
				{
					x: 9,
					y: 4,
					r: 36,
					set: "B",
					ord: 1,
					loc: 0,
					alt: [36, 30, 40, 42],
				},
				{
					x: 9,
					y: 5,
					r: 45,
					set: "A",
					ord: 1,
					loc: 2,
					alt: [40, 48, 45, 49],
				},
				{
					x: 9,
					y: 6,
					r: 54,
					set: "B",
					ord: 2,
					loc: 0,
					alt: [54, 44, 50, 42],
				},
				{
					x: 9,
					y: 7,
					r: 63,
					set: "A",
					ord: 0,
					loc: 3,
					alt: [64, 80, 77, 63],
				},
				{
					x: 9,
					y: 8,
					r: 72,
					set: "B",
					ord: 1,
					loc: 3,
					alt: [70, 66, 88, 72],
				},
				{
					x: 9,
					y: 9,
					r: 81,
					set: "A",
					ord: 2,
					loc: 1,
					alt: [64, 81, 80, 77],
				},
				{
					x: 9,
					y: 10,
					r: 90,
					set: "B",
					ord: 1,
					loc: 2,
					alt: [108, 84, 90, 88],
				},
				{
					x: 9,
					y: 11,
					r: 99,
					set: "A",
					ord: 0,
					loc: 2,
					alt: [121, 120, 99, 100],
				},
				{
					x: 9,
					y: 12,
					r: 108,
					set: "B",
					ord: 2,
					loc: 3,
					alt: [90, 84, 88, 108],
				},
				{
					x: 10,
					y: 2,
					r: 20,
					set: "A",
					ord: 0,
					loc: 0,
					alt: [20, 24, 27, 32],
				},
				{
					x: 10,
					y: 3,
					r: 30,
					set: "B",
					ord: 1,
					loc: 0,
					alt: [30, 22, 36, 24],
				},
				{
					x: 10,
					y: 4,
					r: 40,
					set: "A",
					ord: 0,
					loc: 0,
					alt: [40, 55, 45, 48],
				},
				{
					x: 10,
					y: 5,
					r: 50,
					set: "B",
					ord: 2,
					loc: 1,
					alt: [40, 50, 44, 36],
				},
				{
					x: 10,
					y: 6,
					r: 60,
					set: "A",
					ord: 2,
					loc: 3,
					alt: [45, 48, 55, 60],
				},
				{
					x: 10,
					y: 7,
					r: 70,
					set: "B",
					ord: 2,
					loc: 0,
					alt: [70, 56, 66, 60],
				},
				{
					x: 10,
					y: 8,
					r: 80,
					set: "A",
					ord: 2,
					loc: 2,
					alt: [77, 64, 80, 72],
				},
				{
					x: 10,
					y: 9,
					r: 90,
					set: "B",
					ord: 1,
					loc: 3,
					alt: [108, 88, 84, 90],
				},
				{
					x: 10,
					y: 10,
					r: 100,
					set: "A",
					ord: 1,
					loc: 1,
					alt: [120, 100, 96, 99],
				},
				{
					x: 10,
					y: 11,
					r: 110,
					set: "B",
					ord: 2,
					loc: 0,
					alt: [110, 108, 90, 88],
				},
				{
					x: 10,
					y: 12,
					r: 120,
					set: "A",
					ord: 2,
					loc: 0,
					alt: [120, 100, 96, 99],
				},
				{
					x: 11,
					y: 2,
					r: 22,
					set: "B",
					ord: 0,
					loc: 2,
					alt: [30, 36, 22, 44],
				},
				{
					x: 11,
					y: 3,
					r: 33,
					set: "A",
					ord: 1,
					loc: 1,
					alt: [40, 33, 27, 24],
				},
				{
					x: 11,
					y: 4,
					r: 44,
					set: "B",
					ord: 1,
					loc: 2,
					alt: [50, 54, 44, 36],
				},
				{
					x: 11,
					y: 5,
					r: 55,
					set: "A",
					ord: 2,
					loc: 1,
					alt: [45, 55, 48, 40],
				},
				{
					x: 11,
					y: 6,
					r: 66,
					set: "B",
					ord: 1,
					loc: 2,
					alt: [60, 70, 66, 72],
				},
				{
					x: 11,
					y: 7,
					r: 77,
					set: "A",
					ord: 0,
					loc: 0,
					alt: [77, 96, 80, 81],
				},
				{
					x: 11,
					y: 8,
					r: 88,
					set: "B",
					ord: 0,
					loc: 2,
					alt: [108, 90, 88, 110],
				},
				{
					x: 11,
					y: 9,
					r: 99,
					set: "A",
					ord: 1,
					loc: 1,
					alt: [81, 99, 96, 100],
				},
				{
					x: 11,
					y: 10,
					r: 110,
					set: "B",
					ord: 2,
					loc: 3,
					alt: [108, 88, 90, 110],
				},
				{
					x: 11,
					y: 11,
					r: 121,
					set: "A",
					ord: 2,
					loc: 2,
					alt: [120, 100, 121, 99],
				},
				{
					x: 11,
					y: 12,
					r: 132,
					set: "B",
					ord: 2,
					loc: 3,
					alt: [108, 110, 90, 132],
				},
				{
					x: 12,
					y: 2,
					r: 24,
					set: "A",
					ord: 0,
					loc: 2,
					alt: [48, 33, 24, 40],
				},
				{
					x: 12,
					y: 3,
					r: 36,
					set: "B",
					ord: 0,
					loc: 2,
					alt: [60, 44, 36, 50],
				},
				{
					x: 12,
					y: 4,
					r: 48,
					set: "A",
					ord: 2,
					loc: 2,
					alt: [33, 40, 48, 24],
				},
				{
					x: 12,
					y: 5,
					r: 60,
					set: "B",
					ord: 2,
					loc: 1,
					alt: [44, 60, 36, 50],
				},
				{
					x: 12,
					y: 6,
					r: 72,
					set: "A",
					ord: 1,
					loc: 1,
					alt: [60, 72, 77, 80],
				},
				{
					x: 12,
					y: 7,
					r: 84,
					set: "B",
					ord: 1,
					loc: 1,
					alt: [70, 84, 88, 90],
				},
				{
					x: 12,
					y: 8,
					r: 96,
					set: "A",
					ord: 2,
					loc: 1,
					alt: [80, 96, 72, 77],
				},
				{
					x: 12,
					y: 9,
					r: 108,
					set: "B",
					ord: 2,
					loc: 2,
					alt: [84, 88, 108, 90],
				},
				{
					x: 12,
					y: 10,
					r: 120,
					set: "A",
					ord: 2,
					loc: 0,
					alt: [120, 99, 96, 100],
				},
				{
					x: 12,
					y: 11,
					r: 132,
					set: "B",
					ord: 2,
					loc: 3,
					alt: [108, 90, 110, 132],
				},
				{
					x: 12,
					y: 12,
					r: 144,
					set: "A",
					ord: 2,
					loc: 2,
					alt: [100, 121, 144, 120],
				},
			];

			let item_test_order = [
				35, 84, 34, 41, 115, 23, 40, 17, 13, 19, 6, 110, 39, 108, 111,
				94, 103, 8, 33, 18, 49, 16, 61, 26, 60, 24, 95, 63, 82, 67, 114,
				118, 54, 106, 21, 71, 64, 83, 107, 70, 20, 9, 99, 56, 74, 14,
				36, 31, 90, 65, 88, 50, 98, 97, 72, 109, 78, 11, 48, 68, 45, 75,
				1, 38, 96, 5, 100, 22, 28, 59, 89, 43, 51, 73, 104, 77, 112, 3,
				62, 32, 47, 69, 81, 15, 7, 85, 57, 29, 10, 117, 116, 52, 119,
				55, 87, 79, 25, 105, 86, 93, 92, 102, 101, 46, 4, 27, 12, 113,
				53, 91, 44, 80, 58, 42, 0, 30, 66, 76, 37, 2,
			];

			var str = "";
			for (var i = 0; i < item_test_order.length; i++) {
				str += item_test_order[i] + "\t";
			}
			console.log(str);
		},*/
	},
	mounted() {
		this.setup();
		//this.create_test_items2();
		//this.set_test_items();
	},
};
</script>

<style lang="stylus">
.mini-balloon-enter-active, .mini-balloon-leave-active {
	transition: all 0.75s;
}

.mini-balloon-enter, .mini-balloon-leave-to {
	opacity: 0;
	transform: translate(350px, 10px);
}
</style>
